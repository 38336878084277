<template>
  <section>
    <div class="page-header m-0">
      <nav aria-label="breadcrumb">
        <ol class="align-items-center breadcrumb d-flex flex-row px-0 py-2">
          <li class="breadcrumb-item active" aria-current="page">
            <div class="align-items-center d-flex flex-row px-0">
              <i class="mdi mdi-settings mdi-18px mr-2"></i>
              ทดสอบ
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card">
      <div class="card-body shadow-sm">
        <div class="max-w-md">
          <label for="recognition-image-input">Choose image</label>
          <input
            type="file"
            accept="image/jpeg, image/png"
            id="recognition-image-input"
          /><br />
          <label for="recognition-confidence-input">Confidence</label>
          <input
            type="number"
            max="100"
            min="0"
            id="recognition-confidence-input"
            value="70"
          /><br />
          <label for="recognition-progress">File recognition progress:</label>
          <progress id="recognition-progress" max="100" value="0">0%</progress>
          <div id="recognition-text"></div>
          <div id="recognition-images">
            <div id="original-image"></div>
            <div id="labeled-image"></div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </section>
</template>

<script>
import { createWorker, PSM } from "tesseract.js";

export default {
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    const recognitionImageElement = document.querySelector(
      "#recognition-image-input"
    );
    const recognitionConfidenceInputElement = document.querySelector(
      "#recognition-confidence-input"
    );
    const recognitionProgressElement = document.querySelector(
      "#recognition-progress"
    );
    const recognitionTextElement = document.querySelector("#recognition-text");
    const originalImageElement = document.querySelector("#original-image");
    const labeledImageElement = document.querySelector("#labeled-image");

    recognitionImageElement.addEventListener("change", () => {
      if (!recognitionImageElement.files) {
        return null;
      }
      const file = recognitionImageElement.files[0];
      (async () => {
        const worker = await createWorker("ssd", 1, {
          langPath : "./",
          cacheMethod: 'none',
          logger: (m) => console.log(m),
        });
        const ret = await worker.recognize(file, {
          tessedit_char_whitelist: "0123456789",
          // tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
        });
        console.log(ret.data.text);
        await worker.terminate();
      })();
      // return tesseract
      //   .recognize(file, {
      //     lang: "eng",
      //   })
      //   .progress(({ progress, status }) => {
      //     if (!progress || !status || status !== "recognizing text") {
      //       return null;
      //     }
      //     const p = (progress * 100).toFixed(2);
      //     recognitionProgressElement.textContent = `${status}: ${p}%`;
      //     recognitionProgressElement.value = p;
      //   });
    });
  },
};
</script>

<!-- <script src="./script.js"></script> -->
<!-- <style>@import "./style.scss";</style> -->
